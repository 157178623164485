import {
  Component, ContentChild,
  ElementRef,
  EventEmitter, forwardRef, HostBinding, HostListener,
  Input, OnChanges,
  OnInit,
  Output,
  Renderer2,
  SimpleChanges, TemplateRef,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import {fromEvent, Observable} from 'rxjs';
import {debounceTime, filter, map} from 'rxjs/operators';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {ProjectService} from '../../providers/data/project.service';
import {HardSkillService} from '../../providers/data/hard-skill.service';
import {Project} from '../../interfaces/project';
import {HardSkill} from '../../interfaces/hard-skill';
import {Router} from '@angular/router';
import {HttpClient, HttpResponse} from '@angular/common/http';

/**
 * Keyboard events
 */
const isArrowUp = keyCode => keyCode === 38;
const isArrowDown = keyCode => keyCode === 40;
const isArrowUpDown = keyCode => isArrowUp(keyCode) || isArrowDown(keyCode);
const isEnter = keyCode => keyCode === 13;
const isBackspace = keyCode => keyCode === 8;
const isDelete = keyCode => keyCode === 46;
const isESC = keyCode => keyCode === 27;
const isTab = keyCode => keyCode === 9;


@Component({
  selector: 'app-autocomplete',
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AutocompleteComponent),
      multi: true
    }
  ],
  encapsulation: ViewEncapsulation.None,
})

export class AutocompleteComponent implements OnInit, OnChanges, ControlValueAccessor {
  @ViewChild('searchInput') searchInput: ElementRef; // input element
  @ViewChild('filteredListElement') filteredListElement: ElementRef; // element of items
  @ViewChild('historyListElement') historyListElement: ElementRef; // element of history items

  inputKeyUp$: Observable<any>; // input events
  inputKeyDown$: Observable<any>; // input events

  public query = ''; // search query
  public filteredList = []; // list of items
  public historyList = []; // list of history items
  public isHistoryListVisible = true;
  public elementRef;
  public selectedIdx = -1;
  public toHighlight = '';
  public notFound = false;
  public isFocused = false;
  public isOpen = false;
  public isScrollToEnd = false;
  public overlay = false;
  private manualOpen = undefined;
  private manualClose = undefined;


  // inputs
  /**
   * Data of items list.
   * It can be array of strings or array of objects.
   */
  @Input() public data = [];
  @Input() public searchKeyword = 'name'; // keyword to filter the list
  @Input() public placeHolder = ''; // input placeholder
  @Input() public initialValue: any; // set initial value

  /**
   * History identifier of history list
   * When valid history identifier is given, then component stores selected item to local storage of user's browser.
   * If it is null then history is hidden.
   * History list is visible if at least one history item is stored.
   */
  @Input() public historyIdentifier: string;

  /**
   * Heading text of history list.
   * If it is null then history heading is hidden.
   */
  @Input() public historyHeading = 'Recently selected';
  @Input() public historyListMaxNumber = 15; // maximum number of items in the history list.
  @Input() public notFoundText = 'Not found'; // set custom text when filter returns empty result
  @Input() public isLoading: boolean; // loading mask
  @Input() public debounceTime: 400; // delay time while typing
  @Input() public disabled: boolean; // input disable/enable
  @Input() public header = false; // input is in header
  @Input() searchbarClosed; // search bar is open

  /**
   * The minimum number of characters the user must type before a search is performed.
   */
  @Input() public minQueryLength = 2;


  // output events
  /** Event that is emitted whenever an input is changed. */
  @Output() inputChanged = new EventEmitter<any>();

  /** Event that is emitted whenever an input is focused. */
  @Output() readonly inputFocused: EventEmitter<void> = new EventEmitter<void>();

  /** Event that is emitted whenever an input is cleared. */
  @Output() readonly inputCleared: EventEmitter<void> = new EventEmitter<void>();

  /** Event that is emitted when the autocomplete panel is opened. */
  @Output() readonly opened: EventEmitter<void> = new EventEmitter<void>();

  /** Event that is emitted when the autocomplete panel is closed. */
  @Output() readonly closed: EventEmitter<void> = new EventEmitter<void>();

  /** Event that is emitted when scrolled to the end of items. */
  @Output() readonly scrolledToEnd: EventEmitter<void> = new EventEmitter<void>();


  // custom templates
  @ContentChild(TemplateRef)
  @Input() itemTemplate: TemplateRef<any>;
  @Input() notFoundTemplate: TemplateRef<any>;

  /**
   * Add class at the directive
   */
  @HostBinding('class')
  get setClass() {
    if (this.header) {
      return 'app-autocomplete-header';
    } else {
      return 'app-autocomplete';
    }
  }

  /**
   * Propagates new value when model changes
   */
  propagateChange: any = () => {};


  /**
   * Writes a new value from the form model into the view,
   * Updates model
   */
  writeValue(value: any) {
    this.query = value;
  }

  /**
   * Registers a handler that is called when something in the view has changed
   */
  registerOnChange(fn) {
    this.propagateChange = fn;
  }

  /**
   * Registers a handler specifically for when a control receives a touch event
   */
  registerOnTouched(fn: () => void): void {
  }

  /**
   * Event that is called when the value of an input element is changed
   */
  onChange(event) {
    this.propagateChange(event.target.value);
  }

  constructor(
    elementRef: ElementRef,
    private renderer: Renderer2,
    private projectService: ProjectService,
    private hardSkillService: HardSkillService,
    private router: Router,
    private http: HttpClient
  ) {
    this.elementRef = elementRef;
  }

  /**
   * Event that is called when the control status changes to or from DISABLED
   */
  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  ngOnInit() {
    this.handleScroll();
    this.initEventStream();
    this.setInitialValue(this.initialValue);
  }

  /**
   * Set initial value
   */
  public setInitialValue(value: any) {
    if (this.header) {
      this.data.push({
        name: 'Accueil',
        type: 'intern',
        url: '/'
      });
    }

    this.data.push({
      name: 'Projets',
      type: 'intern',
      url: '/projects'
    });

    this.data.push({
      name: 'Compétences',
      type: 'intern',
      url: '/skills'
    });

    // this.data.push({
    //   name: 'Contact',
    //   type: 'modal',
    //   content: ''
    // });
    //
    // this.data.push({
    //   name: 'E-mail',
    //   type: 'modal',
    //   content: ''
    // });
    // this.data.push({
    //   name: 'Téléphone',
    //   type: 'modal',
    //   content: ''
    // });
    //
    // this.data.push({
    //   name: 'Linkedin',
    //   type: 'modal',
    //   content: ''
    // });

    this.data.push({
      name: 'CV',
      type: 'url',
      // tslint:disable-next-line:max-line-length
      url: 'https://firebasestorage.googleapis.com/v0/b/kevin-hilairet.appspot.com/o/cv%2Fcv-fr.pdf?alt=media&token=45212664-9973-41e2-853c-31133dd53e56'
    });

    this.hardSkillService.hardSkills.forEach((hardSkill: HardSkill) => {
      this.data.push({
        id: hardSkill.id,
        name: hardSkill.name,
        type: 'hard-skill'
      });
    });

    this.projectService.projects.forEach((project: Project) => {
      this.data.push({
        id: project.id,
        name: project.name,
        type: 'project'
      });
    });

    if (this.initialValue) {
      this.select(value);
    }
  }

  /**
   * Update search results
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes &&
      changes.data &&
      Array.isArray(changes.data.currentValue)
    ) {
      this.handleItemsChange();
      if (!changes.data.firstChange && this.isFocused) {
        this.handleOpen();
      }
    }
  }

  /**
   * Items change
   */
  public handleItemsChange() {
    this.isScrollToEnd = false;
    if (!this.isOpen) {
      return;
    }

    this.filteredList = this.data;
  }

  /**
   * Filter data
   */
  public filterList() {
    this.selectedIdx = -1;
    this.initSearchHistory();
    if (this.query != null && this.data) {
      this.toHighlight = this.query;
      this.filteredList = this.data.filter((item: any) => {
        if (typeof item === 'string') {
          // string logic, check equality of strings
          return item.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').indexOf(this.query.toLowerCase()) > -1;
        } else if (typeof item === 'object' && item.constructor === Object) {
          // object logic, check property equality
          return item[this.searchKeyword].toLowerCase()
            .normalize('NFD').replace(/[\u0300-\u036f]/g, '').indexOf(this.query.toLowerCase()) > -1;
        }
      });
    } else {
      this.notFound = false;
    }
  }


  /**
   * Check type of item in the list.
   */
  isType(item) {
    return typeof item === 'string';
  }

  /**
   * Event by item
   */
  handleItem(item) {
    if (item.type === 'project') {
      this.router.navigate(['/projects/', item.id]);
    } else if (item.type === 'hard-skill') {
      this.router.navigate(['/skills'], {fragment: item.id});
    } else if (item.type === 'file') {
      this.http.get(item.url, {responseType: 'blob', observe: 'response'})
        .subscribe((file: HttpResponse<Blob>) => window.location.href = file.url);
    } else if (item.type === 'intern') {
      this.router.navigate([item.url]);
    } else if (item.type === 'url') {
      window.open(item.url, '_blank');
    }
  }

  /**
   * Select item in the list.
   */
  public select(item) {
    this.query = !this.isType(item) ? item[this.searchKeyword] : item;
    this.isOpen = true;
    this.overlay = false;

    this.handleItem(item);

    this.propagateChange(item);

    if (this.initialValue) {
      // check if history already exists in localStorage and then update
      const history = window.localStorage.getItem(`${this.historyIdentifier}`);
      if (history) {
        let existingHistory = JSON.parse(localStorage[`${this.historyIdentifier}`]);
        if (!(existingHistory instanceof Array)) {
          existingHistory = [];
        }

        // check if selected item exists in existingHistory
        if (!existingHistory.some((existingItem) => !this.isType(existingItem)
          ? existingItem[this.searchKeyword] === item[this.searchKeyword] : existingItem === item)) {
          existingHistory.unshift(item);
          localStorage.setItem(`${this.historyIdentifier}`, JSON.stringify(existingHistory));

          // check if items don't exceed max allowed number
          if (existingHistory.length >= this.historyListMaxNumber) {
            existingHistory.splice(existingHistory.length - 1, 1);
            localStorage.setItem(`${this.historyIdentifier}`, JSON.stringify(existingHistory));
          }
        } else {
          // if selected item exists in existingHistory swap to top in array
          if (!this.isType(item)) {
            // object logic
            const copiedExistingHistory = existingHistory.slice(); // copy original existingHistory array
            const selectedIndex = copiedExistingHistory.map((el) => el[this.searchKeyword]).indexOf(item[this.searchKeyword]);
            copiedExistingHistory.splice(selectedIndex, 1);
            copiedExistingHistory.splice(0, 0, item);
            localStorage.setItem(`${this.historyIdentifier}`, JSON.stringify(copiedExistingHistory));
          } else {
            // string logic
            const copiedExistingHistory = existingHistory.slice(); // copy original existingHistory array
            copiedExistingHistory.splice(copiedExistingHistory.indexOf(item), 1);
            copiedExistingHistory.splice(0, 0, item);
            localStorage.setItem(`${this.historyIdentifier}`, JSON.stringify(copiedExistingHistory));
          }
        }
      } else {
        this.saveHistory(item);
      }
    } else {
      this.saveHistory(item);
    }
    this.handleClose();
  }

  /**
   * Document click
   * @param e event
   */
  @HostListener('document:click', ['$event'])
  public handleClick(e) {
    let clickedComponent = e.target;
    let inside = false;
    do {
      if (clickedComponent === this.elementRef.nativeElement) {
        inside = true;
        if (this.filteredList.length) {
          this.handleOpen();
        }
      }
      clickedComponent = clickedComponent.parentNode;
    } while (clickedComponent);
    if (!inside) {
      this.handleClose();
    }
  }

  /**
   * Handle body overlay
   */
  handleOverlay() {
    this.overlay = false;
  }

  /**
   * Scroll items
   */
  public handleScroll() {
    this.renderer.listen(this.filteredListElement.nativeElement, 'scroll', () => {
      this.scrollToEnd();
    });
  }

  /**
   * Define panel state
   */
  setPanelState(event) {
    if (event) {
      event.stopPropagation();
    }
    // If controls are untouched
    if (typeof this.manualOpen === 'undefined'
      && typeof this.manualClose === 'undefined') {
      this.isOpen = false;
      this.handleOpen();
    }

    // If one of the controls is untouched and other is deactivated
    if (typeof this.manualOpen === 'undefined'
      && this.manualClose === false
      || typeof this.manualClose === 'undefined'
      && this.manualOpen === false) {
      this.isOpen = false;
      this.handleOpen();
    }

    // if controls are touched but both are deactivated
    if (this.manualOpen === false && this.manualClose === false) {
      this.isOpen = false;
      this.handleOpen();
    }

    // if open control is touched and activated
    if (this.manualOpen) {
      this.isOpen = false;
      this.handleOpen();
      this.manualOpen = false;
    }

    // if close control is touched and activated
    if (this.manualClose) {
      this.isOpen = true;
      this.handleClose();
      this.manualClose = false;
    }
  }

  /**
   * Manual controls
   */
  open() {
    this.manualOpen = true;
    this.isOpen = false;
    this.handleOpen();
  }

  close() {
    this.manualClose = true;
    this.isOpen = true;
    this.handleClose();
  }

  focus() {
    this.handleFocus(event);
  }

  clear() {
    this.remove(event);
  }

  /**
   * Remove search query
   */
  public remove(e) {
    e.stopPropagation();
    this.query = '';
    this.inputCleared.emit();
    this.propagateChange(this.query);
    this.setPanelState(e);
    this.notFound = false;

    if (this.header) {
      this.handleClose();
      const searchBtn = document.querySelector('.search');
      const searchInput = document.querySelector('.search-input');
      searchBtn.classList.remove('active');
      searchInput.classList.remove('active');
    }
  }

  /**
   * Initialize historyList search
   */
  initSearchHistory() {
    this.isHistoryListVisible = false;
    if (this.historyIdentifier && !this.query) {
      const history = window.localStorage.getItem(`${this.historyIdentifier}`);
      if (history) {
        this.isHistoryListVisible = true;
        this.filteredList = [];
        this.historyList = history ? JSON.parse(history) : [];
      } else {
        this.isHistoryListVisible = false;
      }
    } else {
      this.isHistoryListVisible = false;
    }
  }

  handleOpen() {
    if (this.isOpen || this.isOpen && !this.isLoading) {
      return;
    }
    // If data exists
    if (this.data && this.data.length) {
      this.isOpen = true;
      this.overlay = true;
      this.filterList();
      this.opened.emit();
    }
  }

  handleClose() {
    if (!this.isOpen) {
      this.isFocused = false;
      return;
    }

    this.query = '';
    this.isOpen = false;
    this.overlay = false;
    this.filteredList = [];
    this.selectedIdx = -1;
    this.notFound = false;
    this.isHistoryListVisible = false;
    this.isFocused = false;
    this.closed.emit();
  }

  handleFocus(e) {
    setTimeout(() => {
      this.searchInput.nativeElement.focus();
    }, 300);
    if (this.isFocused) {
      return;
    }
    this.inputFocused.emit(e);
    // if data exists then open
    if (this.data && this.data.length) {
      this.setPanelState(event);
    }
    this.isFocused = true;
  }

  scrollToEnd(): void {
    if (this.isScrollToEnd) {
      return;
    }

    const scrollTop = this.filteredListElement.nativeElement
      .scrollTop;
    const scrollHeight = this.filteredListElement.nativeElement
      .scrollHeight;
    const elementHeight = this.filteredListElement.nativeElement
      .clientHeight;
    const atBottom = scrollHeight === scrollTop + elementHeight;
    if (atBottom) {
      this.scrolledToEnd.emit();
      this.isScrollToEnd = true;
    }
  }

  /**
   * Initialize keyboard events
   */
  initEventStream() {
    this.inputKeyUp$ = fromEvent(
      this.searchInput.nativeElement, 'keyup'
    ).pipe(map(
      (e: any) => e
    ));

    this.inputKeyDown$ = fromEvent(
      this.searchInput.nativeElement,
      'keydown'
    ).pipe(map(
      (e: any) => e
    ));

    this.listenEventStream();
  }

  /**
   * Listen keyboard events
   */
  listenEventStream() {
    // key up event
    this.inputKeyUp$
      .pipe(
        filter(e =>
          !isArrowUpDown(e.keyCode) &&
          !isEnter(e.keyCode) &&
          !isESC(e.keyCode) &&
          !isTab(e.keyCode)),
        debounceTime(this.debounceTime)
      ).subscribe(e => {
      this.onKeyUp(e);
    });

    // cursor up & down
    this.inputKeyDown$.pipe(filter(
      e => isArrowUpDown(e.keyCode)
    )).subscribe(e => {
      e.preventDefault();
      this.onFocusItem(e);
    });

    // enter keyup
    this.inputKeyUp$.pipe(filter(e => isEnter(e.keyCode))).subscribe(e => {
      // this.onHandleEnter();
    });

    // enter keydown
    this.inputKeyDown$.pipe(filter(e => isEnter(e.keyCode))).subscribe(e => {
      this.onHandleEnter();
    });

    // ESC
    this.inputKeyUp$.pipe(
      filter(e => isESC(e.keyCode),
        debounceTime(100))
    ).subscribe(e => {
      this.onEsc();
    });

    // delete
    this.inputKeyDown$.pipe(
      filter(e => isBackspace(e.keyCode) || isDelete(e.keyCode))
    ).subscribe(e => {
      this.onDelete();
    });
  }

  /**
   * on keyup == when input changed
   * @param e event
   */
  onKeyUp(e) {
    this.notFound = false; // search results are unknown while typing
    // if input is empty
    if (!this.query) {
      this.notFound = false;
      this.inputChanged.emit(e.target.value);
      this.inputCleared.emit();
      // this.filterList();
      this.setPanelState(e);
    }
    // if query >= to minQueryLength
    if (this.query.length >= this.minQueryLength) {
      this.inputChanged.emit(e.target.value);
      this.filterList();

      // If no results found
      if (!this.filteredList.length) {
        this.notFoundText ? this.notFound = true : this.notFound = false;
      }
    }
  }


  /**
   * Keyboard arrow top and arrow bottom
   * @param e event
   */
  onFocusItem(e) {
    // move arrow up and down on filteredList or historyList
    if (!this.historyList.length || !this.isHistoryListVisible) {
      // filteredList
      const totalNumItem = this.filteredList.length;
      if (e.key === 'ArrowDown') {
        let sum = this.selectedIdx;
        sum = (this.selectedIdx === null) ? 0 : sum + 1;
        this.selectedIdx = (totalNumItem + sum) % totalNumItem;
        this.scrollToFocusedItem(this.selectedIdx);
      } else if (e.key === 'ArrowUp') {
        if (this.selectedIdx === -1) {
          this.selectedIdx = 0;
        }
        this.selectedIdx = (totalNumItem + this.selectedIdx - 1) % totalNumItem;
        this.scrollToFocusedItem(this.selectedIdx);
      }
    } else {
      // historyList
      const totalNumItem = this.historyList.length;
      if (e.key === 'ArrowDown') {
        let sum = this.selectedIdx;
        sum = (this.selectedIdx === null) ? 0 : sum + 1;
        this.selectedIdx = (totalNumItem + sum) % totalNumItem;
        this.scrollToFocusedItem(this.selectedIdx);
      } else if (e.key === 'ArrowUp') {
        if (this.selectedIdx === -1) {
          this.selectedIdx = 0;
        }
        this.selectedIdx = (totalNumItem + this.selectedIdx - 1) % totalNumItem;
        this.scrollToFocusedItem(this.selectedIdx);
      }
    }
  }

  /**
   * Scroll to focused item
   * * @param index
   */
  scrollToFocusedItem(index) {
    let listElement = null;
    // Define list element
    if (!this.historyList.length || !this.isHistoryListVisible) {
      // filteredList element
      listElement = this.filteredListElement.nativeElement;
    } else {
      // historyList element
      listElement = this.historyListElement.nativeElement;
    }

    const items = Array.prototype.slice.call(listElement.childNodes).filter((node: any) => {
      if (node.nodeType === 1) {
        // if node is element
        return node.className.includes('item');
      } else {
        return false;
      }
    });

    if (!items.length) {
      return;
    }

    const listHeight = listElement.offsetHeight;
    const itemHeight = items[index].offsetHeight;
    const visibleTop = listElement.scrollTop;
    const visibleBottom = listElement.scrollTop + listHeight - itemHeight;
    const targetPosition = items[index].offsetTop;

    if (targetPosition < visibleTop) {
      listElement.scrollTop = targetPosition;
    }

    if (targetPosition > visibleBottom) {
      listElement.scrollTop = targetPosition - listHeight + itemHeight;
    }
  }

  /**
   * Select item on enter click
   */
  onHandleEnter() {
    // click enter to choose item from filteredList or historyList
    if (this.selectedIdx > -1) {
      if (!this.historyList.length || !this.isHistoryListVisible) {
        // filteredList
        this.query = !this.isType(this.filteredList[this.selectedIdx])
          ? this.filteredList[this.selectedIdx][this.searchKeyword]
          : this.filteredList[this.selectedIdx];

        this.saveHistory(this.filteredList[this.selectedIdx]);
        this.select(this.filteredList[this.selectedIdx]);
      } else {
        // historyList
        this.query = !this.isType(this.historyList[this.selectedIdx])
          ? this.historyList[this.selectedIdx][this.searchKeyword]
          : this.historyList[this.selectedIdx];
        this.saveHistory(this.historyList[this.selectedIdx]);
        this.select(this.historyList[this.selectedIdx]);
      }
    } else if (this.filteredList.length > 0) {
      // filteredList
      this.query = !this.isType(this.filteredList[0])
        ? this.filteredList[0][this.searchKeyword]
        : this.filteredList[0];

      this.saveHistory(this.filteredList[0]);
      this.select(this.filteredList[0]);
    }
    this.isHistoryListVisible = false;
    this.handleClose();
  }

  /**
   * Esc click
   */
  onEsc() {
    this.searchInput.nativeElement.blur();
    this.handleClose();
  }

  /**
   * Delete click
   */
  onDelete() {
    // panel is open on delete
    this.isOpen = true;
  }


  /**
   * Select item to save in localStorage
   */
  saveHistory(selected) {
    if (this.historyIdentifier) {
      // check if selected item exists in historyList
      if (!this.historyList.some((item) => !this.isType(item)
        ? item[this.searchKeyword] === selected[this.searchKeyword] : item === selected)) {
        this.saveHistoryToLocalStorage([selected, ...this.historyList]);

        // check if items don't exceed max allowed number
        if (this.historyList.length >= this.historyListMaxNumber) {
          this.historyList.splice(this.historyList.length - 1, 1);
          this.saveHistoryToLocalStorage([selected, ...this.historyList]);
        }
      } else {
        // if selected item exists in historyList swap to top in array
        if (!this.isType(selected)) {
          // object logic
          const copiedHistoryList = this.historyList.slice(); // copy original historyList array
          const selectedIndex = copiedHistoryList.map((item) => item[this.searchKeyword]).indexOf(selected[this.searchKeyword]);
          copiedHistoryList.splice(selectedIndex, 1);
          copiedHistoryList.splice(0, 0, selected);
          this.saveHistoryToLocalStorage([...copiedHistoryList]);
        } else {
          // string logic
          const copiedHistoryList = this.historyList.slice(); // copy original historyList array
          copiedHistoryList.splice(this.historyList.indexOf(selected), 1);
          copiedHistoryList.splice(0, 0, selected);
          this.saveHistoryToLocalStorage([...copiedHistoryList]);
        }
      }
    }
  }

  /**
   * Save item in localStorage
   */
  saveHistoryToLocalStorage(selected) {
    window.localStorage.setItem(
      `${this.historyIdentifier}`,
      JSON.stringify(selected)
    );
  }

  /**
   * Remove item from localStorage
   */
  removeHistoryItem(index, e) {
    e.stopPropagation();
    this.historyList = this.historyList.filter((v, i) => i !== index);
    this.saveHistoryToLocalStorage(this.historyList);
    if (this.historyList.length === 0) {
      window.localStorage.removeItem(`${this.historyIdentifier}`);
      this.filterList();
    }
  }

  /**
   * Reset localStorage
   * @param e event
   */
  resetHistoryList(e) {
    e.stopPropagation();
    this.historyList = [];
    window.localStorage.removeItem(`${this.historyIdentifier}`);
    this.filterList();
  }
}
