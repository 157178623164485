import { Injectable } from '@angular/core';
import {AngularFirestore} from '@angular/fire/firestore';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {HardSkill} from '../../interfaces/hard-skill';

const COLLECTION  = 'hard_skills';

@Injectable({
  providedIn: 'root'
})
export class HardSkillService {

  private _hardSkills: HardSkill[];

  get hardSkills(): HardSkill[] {
    return this._hardSkills;
  }

  set hardSkills(value: HardSkill[]) {
    this._hardSkills = value;
  }

  constructor(public db: AngularFirestore) { }

  all(): Observable<any[]> {
    return this.db.collection(COLLECTION).snapshotChanges().pipe(map(changes => {
      return changes.map(change => {
        const data = change.payload.doc.data() as HardSkill;
        const id = change.payload.doc.id;
        return { id, ...data };
      });
    }));
  }

  show(id: string): Observable<any> {
    return this.db.collection(COLLECTION).doc(id).valueChanges();
  }

  add(hardSkill: any) {
    return this.db.collection(COLLECTION).add(hardSkill);
  }

  addWithKey(key: string, hardSkill: any) {
    return this.db.collection(COLLECTION).doc(key).set(hardSkill);
  }

  update(id: string, hardSkill: any) {
    return this.db.collection(COLLECTION).doc(id).update(hardSkill);
  }

  delete(id: string) {
    return this.db.collection(COLLECTION).doc(id).delete();
  }
}
