import { Component, OnInit } from '@angular/core';
import {Project} from '../../../interfaces/project';
import {ProjectService} from '../../../providers/data/project.service';
import {Title} from '@angular/platform-browser';

@Component({
  selector: 'app-project-list',
  templateUrl: './project-list.component.html',
  styleUrls: ['./project-list.component.scss']
})
export class ProjectListComponent implements OnInit {
  projects: Project[];
  projectHovered: string;

  constructor(private service: ProjectService, private titleService: Title) {
  }

  ngOnInit() {
    this.projects = this.service.projects;
    this.titleService.setTitle('Mes projets - Kevin Hilairet');
  }

  mouseEnter(id: string) {
    this.projectHovered = id;
  }

  mouseLeave() {
    this.projectHovered = undefined;
  }
}
