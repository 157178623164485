import {Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild} from '@angular/core';

import * as $ from 'jquery';
import {ProjectService} from '../../providers/data/project.service';
import {Project} from '../../interfaces/project';
import {HardSkillService} from '../../providers/data/hard-skill.service';
import {HardSkill} from '../../interfaces/hard-skill';
import {Router} from '@angular/router';
import {Title} from '@angular/platform-browser';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {saveAs} from 'file-saver';

@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.scss']
})
export class HomepageComponent implements OnInit, OnDestroy {
  randomWord = true;
  projects: Project[];
  hardSkills: HardSkill[];

  @ViewChild('autocomplete') autocomplete;

  constructor(
    private projectService: ProjectService,
    private hardSkillService: HardSkillService,
    private router: Router,
    private titleService: Title
  ) {
    this.projects = this.projectService.projects;
    this.hardSkills = this.hardSkillService.hardSkills;
  }

  ngOnInit() {
    this.titleService.setTitle('Kévin Hilairet');

    this.projects.forEach((project: Project) => {
      this.makeDiv(project.name);
    });

    this.hardSkills.forEach((hardSkill: HardSkill) => {
      this.makeDiv(hardSkill.name);
    });

    this.autocomplete.focus();
  }

  ngOnDestroy(): void {
    this.randomWord = false;
  }

  onChangeSearch(val: string) {
    if (val.length < 2) {
      this.autocomplete.close();
    }
  }

  onFocused(e) {
    this.autocomplete.close();
    // do something when input is focused
  }

  makeDiv(word: string) {
    if (this.randomWord) {
      const self = this;
      const color = '#' + Math.round(0xffffff * Math.random()).toString(16);
      const $newdiv = $('<div>' + word + '</div>').css({
        'font-size': '1em',
        'text-transform': 'uppercase'
      });

      const divSizeWidth = 11 * word.length;

      const posx = (Math.random() * (document.body.clientWidth - +divSizeWidth.toFixed())).toFixed();
      const posy = Math.random() * document.body.clientHeight - 50;

      $newdiv.css({
        position: 'absolute',
        left: posx + 'px',
        top: posy + 'px',
        display: 'none',
        color: 'rgba(255, 255, 255, 0.1)'
      }).appendTo('.container-fluid').delay(Math.random() * Math.floor(10) * 1000).fadeIn(1000).fadeOut(1000, function() {
        $(this).remove();
        self.makeDiv(word);
      });
    }
  }
}
