import {AfterViewInit, Component, ElementRef, HostListener, Input, ViewChild} from '@angular/core';

@Component({
  selector: 'app-particle',
  templateUrl: './particle.component.html',
  styleUrls: ['./particle.component.scss']
})
export class ParticleComponent implements AfterViewInit {

  @ViewChild('container') public container: ElementRef;
  @Input() public density: number;
  private canvas: any;

  private ctx: CanvasRenderingContext2D;
  private particles = [];

  options = {
    velocity: 1, // the higher the faster
    density: 25000, // the lower the denser
    netLineDistance: 200,
    netLineColor: '#929292',
    particleColors: '#aaa' // ['#6D4E5C', '#aaa', '#FFC458' ]
  };

  createIntervalId: any;
  animationFrame: any;

  constructor() { }

  ngAfterViewInit(): void {
    if (this.density) {
      this.options.density = this.density;
    }

    this.canvas = document.createElement('canvas');
    this.sizeCanvas();
    this.container.nativeElement.appendChild(this.canvas);
    this.ctx = this.canvas.getContext('2d');

    this.createParticles();
    this.animationFrame = requestAnimationFrame(this.update.bind(this));

  }

  sizeCanvas() {
    this.canvas.width = this.container.nativeElement.offsetWidth;
    this.canvas.height = this.container.nativeElement.offsetHeight;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
    this.sizeCanvas();
    this.createParticles();
  }

  createParticles() {
    let counter = 0;
    const quantity = this.canvas.width * this.canvas.height / this.options.density;

    clearInterval(this.createIntervalId);
    this.createIntervalId = setInterval(() => {
      if (counter < quantity - 1) {
        // Create particle object
        this.newParticle();
      } else {
        clearInterval(this.createIntervalId);
      }
      counter++;
    }, 250);
  }

  newParticle() {
    const particle = {
      particleColor: this.options.particleColors,
      radius: Math.random() * (1.5 - 2.5) + 1.5,
      opacity: 0,
      x: Math.random() * this.canvas.width,
      y: Math.random() * this.canvas.height,
      velocity: {
        x: (Math.random() - 0.5) * this.options.velocity,
        y: (Math.random() - 0.5) * this.options.velocity
      }
    };

    this.particles.push(particle);
  }

  updateParticle(particle) {
    if (particle.opacity < 1) {
      particle.opacity += 0.01;
    } else {
      particle.opacity = 1;
    }

    // Decrease opacity
    if ((particle.x > this.canvas.width || particle.x < 0) || (particle.y > this.canvas.height || particle.y < 0)) {
      this.removeParticle(particle);
    } else {
      const rate = 0.1;

      if (particle.x > this.canvas.width - (this.canvas.width * rate)) {
        particle.opacity = (this.canvas.width - particle.x) / (this.canvas.width * rate);

      } else if (particle.x < this.canvas.width * rate) {
        particle.opacity = particle.x / (this.canvas.width * rate);

      } else if (particle.y > this.canvas.height - (this.canvas.height * rate)) {
        particle.opacity = (this.canvas.height - particle.y) / (this.canvas.height * rate);
        console.log(particle.opacity);

      } else if (particle.y < this.canvas.height * rate) {
        particle.opacity = particle.y / (this.canvas.height * rate);
      }

      // Update position
      particle.x += particle.velocity.x;
      particle.y += particle.velocity.y;
    }
  }

  removeParticle(particle) {
    const i = this.particles.findIndex(p => p === particle);
    this.particles.splice(i, 1);
    this.newParticle();
  }

  drawParticle(particle) {
    if (particle) {
      this.ctx.beginPath();
      this.ctx.fillStyle = particle.particleColor;
      this.ctx.globalAlpha = particle.opacity;
      this.ctx.arc(particle.x, particle.y, particle.radius, 0, 2 * Math.PI);
      this.ctx.fill();
    }
  }

  update() {
    if (this.canvas) {

      this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
      this.ctx.globalAlpha = 1;

      // Draw connections
      for (let i = 0; i < this.particles.length; i++) {
        for (let j = this.particles.length - 1; j > i; j--) {
          let distance;
          const p1 = this.particles[i];
          const p2 = this.particles[j];

          // check very simply if the two points are even a candidate for further measurements
          distance = Math.min(Math.abs(p1.x - p2.x), Math.abs(p1.y - p2.y));
          if (distance > this.options.netLineDistance) {
            continue;
          }

          // the two points seem close enough, now let's measure precisely
          distance = Math.sqrt(
            Math.pow(p1.x - p2.x, 2) +
            Math.pow(p1.y - p2.y, 2)
          );
          if (distance > this.options.netLineDistance) {
            continue;
          }

          this.ctx.beginPath();
          this.ctx.strokeStyle = this.options.netLineColor;
          this.ctx.globalAlpha = (this.options.netLineDistance - distance) / this.options.netLineDistance * p1.opacity * p2.opacity;
          this.ctx.lineWidth = 0.7;
          this.ctx.moveTo(p1.x, p1.y);
          this.ctx.lineTo(p2.x, p2.y);
          this.ctx.stroke();
        }
      }

      // Draw particles
      for (let i = 0; i < this.particles.length; i++) {
        this.updateParticle(this.particles[i]);
        this.drawParticle(this.particles[i]);
      }

      if (this.options.velocity !== 0) {
        this.animationFrame = requestAnimationFrame(this.update.bind(this));
      }

    } else {
      cancelAnimationFrame(this.animationFrame);
    }
  }

}
