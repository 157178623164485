import {Injectable} from '@angular/core';
import {ProjectService} from './providers/data/project.service';
import {HardSkillService} from './providers/data/hard-skill.service';
import {SoftSkillService} from './providers/data/soft-skill.service';

@Injectable()
export class AppLoadService {

  constructor(private projectService: ProjectService, private hardSkillService: HardSkillService,
              private softSkillService: SoftSkillService) { }

  getApi(): Promise<any> {
    const promiseProjects = new Promise((resolve, reject) => {
      this.projectService.all().subscribe((projects) => {
        this.projectService.projects = projects;
        resolve();
      });
    });

    const promiseHardSkills = new Promise((resolve, reject) => {
      this.hardSkillService.all().subscribe((hardSkills) => {
        this.hardSkillService.hardSkills = hardSkills;
        resolve();
      });
    });

    const promiseSoftSkills = new Promise((resolve, reject) => {
      this.softSkillService.all().subscribe((softSkills) => {
        this.softSkillService.softSkills = softSkills;
        resolve();
      });
    });

    return Promise.all([promiseProjects, promiseHardSkills, promiseSoftSkills]).then(() => {
      return;
    });
  }
}
