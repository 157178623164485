import { BrowserModule } from '@angular/platform-browser';
import {APP_INITIALIZER, NgModule} from '@angular/core';
import { AngularFireModule } from '@angular/fire';
import { environment } from '../environments/environment';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { HomepageComponent } from './pages/homepage/homepage.component';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { ProjectsModule } from './pages/projects/projects.module';

import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import {AppLoadService} from './app-load.service';
import {HttpClientModule} from '@angular/common/http';
import {ParticleModule} from './components/particle/particle.module';
import {AutocompleteModule} from './components/autocomplete/autocomplete.module';
import {SkillsModule} from './pages/skills/skills.module';
import {ChartModule} from './components/chart/chart.module';

registerLocaleData(localeFr, 'fr');

export function getApi(appLoadService: AppLoadService) {
  return () => appLoadService.getApi();
}

@NgModule({
  declarations: [
    AppComponent,
    HomepageComponent,
    PageNotFoundComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    ProjectsModule,
    SkillsModule,
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    AngularFireStorageModule,
    AutocompleteModule,
    ParticleModule,
    ChartModule
  ],
  providers: [
    AppLoadService,
    { provide: APP_INITIALIZER, useFactory: getApi, deps: [AppLoadService], multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
