import {Component, ElementRef, HostListener, Input, OnInit, ViewChild} from '@angular/core';

@Component({
  selector: 'app-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.scss']
})
export class ChartComponent implements OnInit {

  @Input() public percentage: number;
  @Input() public title: string;
  @Input() public active: boolean;
  @ViewChild('chart') chart: ElementRef;
  @ViewChild('ringChart') ringChart: ElementRef;
  size: number;
  clipmask: string;
  clipfill: string;
  csshalf: string;
  cssfull: string;

  constructor() { }

  ngOnInit() {
    this.sizeChar();

    this.csshalf = 'rotate(' + 180 * this.percentage / 100 + 'deg)';
    this.cssfull = 'rotate(' + 360 * this.percentage / 100 + 'deg)';
  }

  @HostListener('window:resize')
  onResize() {
    this.sizeChar();
  }

  private sizeChar() {
    this.size = this.ringChart.nativeElement.clientWidth;
    this.chart.nativeElement.style.height = this.size + 'px';

    this.clipmask = 'rect(0,' + this.size + 'px,' + this.size + 'px,' + this.size / 2 + 'px)';
    this.clipfill = 'rect(0,' + this.size / 2 + 'px,' + this.size + 'px,0)';
  }
}
