import {Component, OnInit} from '@angular/core';
import {HardSkill} from '../../../interfaces/hard-skill';
import {HardSkillService} from '../../../providers/data/hard-skill.service';
import {Title} from '@angular/platform-browser';
import {Router} from '@angular/router';
import {SoftSkillService} from '../../../providers/data/soft-skill.service';
import {Category} from '../../../interfaces/category';
import {SoftSkill} from '../../../interfaces/soft-skill';

@Component({
  selector: 'app-skill-list',
  templateUrl: './skill-list.component.html',
  styleUrls: ['./skill-list.component.scss']
})
export class SkillListComponent implements OnInit {

  skillSelected: string;
  categorySelected: any;

  categories = [
    {
      name: 'Tous',
      nameSelect: 'Tous',
      slug: 'tous',
      selected: true,
      skills: [],
      items: [
        {
          name: 'Hard Skills',
          nameSelect: '- Hard Skills',
          slug: 'hard-skills',
          selected: false,
          skills: [],
          items: [
            {
              languages: {
                fr: {
                  name: 'Développement',
                  nameSelect: '-- Développement',
                },
                en: {
                  name: 'Development',
                  nameSelect: '-- Development',
                }
              },
              slug: 'development',
              selected: false,
              skills: [],
              items: [
                {
                  name: 'Backend',
                  nameSelect: '--- Backend',
                  slug: 'backend',
                  skills: [],
                  selected: false
                },
                {
                  name: 'Frontend',
                  nameSelect: '--- Frontend',
                  slug: 'frontend',
                  skills: [],
                  selected: false
                }
              ]
            },
            {
              name: 'Design',
              nameSelect: '-- Design',
              slug: 'design',
              skills: [],
              selected: false
            }
          ]
        },
        {
          name: 'Soft Skills',
          nameSelect: '- Soft Skills',
          slug: 'soft-skills',
          skills: [],
          selected: false
        }
      ]
    }
  ];

  constructor(private hardSkillService: HardSkillService, private softkillService: SoftSkillService,
              private titleService: Title, private router: Router) {
  }

  ngOnInit() {
    this.titleService.setTitle('Mes compétences - Kevin Hilairet');

    this.initCategories();
    this.categorySelected = this.categories[0];

    if (this.router.routerState.snapshot.root.fragment) {
      this.skillSelected = this.router.routerState.snapshot.root.fragment;
      setTimeout(() => {
        document.querySelector('#' + this.skillSelected).scrollIntoView({ behavior: 'smooth', block: 'center' });
      }, 300);
    }
  }

  /**
   * Init skills in categories
   */
  initCategories() {
    this.hardSkillService.hardSkills.forEach((skill) => {
      if (skill.category) {
        this.findCategory(this.categories[0], skill);
      } else {
        this.categories[0].items[0].skills.push(skill);
      }
    });

    this.softkillService.softSkills.forEach((skill) => {
      if (skill.category) {
        this.findCategory(this.categories[0], skill);
      } else {
        this.categories[0].items[1].skills.push(skill);
      }
    });
  }

  /**
   * Add skill at the category with an recursive
   *
   * @param category
   * @param skill
   */
  private findCategory(category: any, skill: HardSkill|SoftSkill) {
    category.items.forEach((c) => {
      if (skill.category === c.slug) {
        c.skills.push(skill);
      } else if (c.items && c.items.length > 0) {
        this.findCategory(c, skill);
      }
    });
  }

  selectCategoryChanged(e) {
    this.findListByCategory(this.categorySelected, this.categories);
  }

  private findListByCategory(category: any, categories: any[]) {
    categories.forEach((c) => {
      if (c.slug === category.slug) {

      } else if (c.items && c.items.length > 0) {
        this.findListByCategory(category, c.items);
      }
    });
  }

  /**
   * Selected category in desktop
   *
   * @param item
   * @param list
   * @param recursive
   */
  toggleCategory(item: any, list: Category[], recursive: boolean = false) {
    if (recursive) {
      item.selected = false;
      item.disabled = false;
    } else {
      item.selected = true;
      this.categorySelected = item;
      list.forEach((cat) => {
        if (cat.slug !== item.slug) {
          cat.disabled = true;
        }
      });
    }

    if (item.items && item.items.length > 0) {
      item.items.forEach((i) => {
        this.toggleCategory(i, item.items, true);
      });
    }
  }
}
