import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {Project, ProjectTranslate} from '../../../interfaces/project';
import {ProjectService} from '../../../providers/data/project.service';
import {Title} from '@angular/platform-browser';

@Component({
  selector: 'app-project-detail',
  templateUrl: './project-detail.component.html',
  styleUrls: ['./project-detail.component.scss']
})
export class ProjectDetailComponent implements OnInit {

  project: Project;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private service: ProjectService, private titleService: Title) { }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      this.project = this.service.getProject(params.get('id'));
      if (!this.project) {
        this.router.navigate(['/projects']);
      } else {
        this.titleService.setTitle(this.project.name + ' - Kevin Hilairet');
      }
    });
  }
}
