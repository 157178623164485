import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {SkillListComponent} from './skill-list/skill-list.component';
import {PageNotFoundComponent} from '../page-not-found/page-not-found.component';

const routes: Routes = [
  { path: 'skills',  component: SkillListComponent },
  // { path: 'skills/:id', component: SkillDetailComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SkillsRoutingModule { }
