import { Pipe, PipeTransform } from '@angular/core';
import {AngularFireStorage} from '@angular/fire/storage';

@Pipe({
  name: 'imgStorage'
})
export class ImgStoragePipe implements PipeTransform {


  constructor(private storage: AngularFireStorage) {
  }

  /**
   * todo : generate error 404, http://{url}/null
   */
  transform(project: any, args?: any): any {
    if (project[args]) {
      const ref = this.storage.ref(project[args]);
      if (ref) {
        return ref.getDownloadURL();
      }
    }

    return 'https://loremflickr.com/320/200/paris?random=' + project.order;
  }

}
