import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SkillListComponent } from './skill-list/skill-list.component';
import {SkillsRoutingModule} from './skills-routing.module';
import {ChartModule} from '../../components/chart/chart.module';
import {ParticleModule} from '../../components/particle/particle.module';
import {FormsModule} from '@angular/forms';

@NgModule({
  declarations: [
    SkillListComponent
  ],
  imports: [
    CommonModule,
    SkillsRoutingModule,
    ChartModule,
    ParticleModule,
    FormsModule
  ]
})
export class SkillsModule { }
