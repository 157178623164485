import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AutocompleteComponent } from './autocomplete.component';
import {HighlightPipe} from './highlight.pipe';
import {FormsModule} from '@angular/forms';

@NgModule({
  declarations: [
    AutocompleteComponent,
    HighlightPipe
  ],
  imports: [
    CommonModule,
    FormsModule
  ],
  exports: [
    AutocompleteComponent,
    HighlightPipe
  ]
})
export class AutocompleteModule { }
