import {Component, OnInit, ViewChild} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {Router} from '@angular/router';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss']
})
export class PageNotFoundComponent implements OnInit {
  @ViewChild('autocomplete') autocomplete;

  constructor(private router: Router, private titleService: Title) {
  }

  ngOnInit() {
    this.titleService.setTitle('Page introuvable - Kevin Hilairet');

    this.autocomplete.focus();
  }

  onChangeSearch(val: string) {
    if (val.length < 2) {
      this.autocomplete.close();
    }
  }

  onFocused(e) {
    this.autocomplete.close();
  }
}
