import { NgModule } from '@angular/core';
import {ParticleComponent} from './particle.component';
import {CommonModule} from '@angular/common';

@NgModule({
  declarations: [
    ParticleComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    ParticleComponent
  ]
})
export class ParticleModule { }
