import {Inject, Injectable, LOCALE_ID} from '@angular/core';
import {AngularFirestore} from '@angular/fire/firestore';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {Project} from '../../interfaces/project';
import {AngularFireStorage} from '@angular/fire/storage';
import {__values} from 'tslib';
import {Image} from '../../interfaces/image';

const COLLECTION  = 'projects';

@Injectable({
  providedIn: 'root'
})
export class ProjectService {

  private _projects: Project[];

  get projects(): Project[] {
    return this._projects;
  }

  set projects(value: Project[]) {
    this._projects = value;
  }

  constructor(private db: AngularFirestore, @Inject(LOCALE_ID) protected localeId: string, private storageService: AngularFireStorage) {
    if (this.localeId === 'en-US') {
      this.localeId = 'en';
    }
  }

  getProject(id: number | string) {
    return this.projects.find(project => project.id === id);
  }

  all(): Observable<any[]> {
    return this.db.collection(COLLECTION, ref => ref.where('enabled', '==', true)).snapshotChanges().pipe(map(changes => {
      return changes.map(change => {
        const project = change.payload.doc.data() as Project;
        const id = change.payload.doc.id;

        return { id, ...project };
      });
    }));
  }

  show(id: string): Observable<any> {
    return this.db.collection(COLLECTION).doc(id).valueChanges();
  }

  translate(id: string): Observable<any> {
    return this.db.collection(COLLECTION).doc(id).collection('languages').doc(this.localeId).valueChanges();
  }

  gallery(id: string): Observable<any> {
    return this.db.collection(COLLECTION).doc(id).collection('gallery').snapshotChanges().pipe(map(changes => {
      return changes.map(change => {
        const image = change.payload.doc.data() as Image;
        const idImage = change.payload.doc.id;

        return { id: idImage, ...image };
      });
    }));
  }

  add(project: any) {
    return this.db.collection(COLLECTION).add(project);
  }

  addWithKey(key: string, project: any) {
    return this.db.collection(COLLECTION).doc(key).set(project);
  }

  update(id: string, project: any) {
    return this.db.collection(COLLECTION).doc(id).update(project);
  }

  delete(id: string) {
    return this.db.collection(COLLECTION).doc(id).delete();
  }
}
