import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ProjectsRoutingModule } from './projects-routing.module';
import { ProjectListComponent } from './project-list/project-list.component';
import { ProjectDetailComponent } from './project-detail/project-detail.component';
import {ImgStoragePipe} from '../../pipes/img-storage.pipe';
import {NgxSkeletonLoaderModule} from 'ngx-skeleton-loader';
import {ParticleModule} from '../../components/particle/particle.module';
import {DocPipe} from '../../pipes/doc.pipe';

@NgModule({
  declarations: [
    ProjectListComponent,
    ProjectDetailComponent,
    ImgStoragePipe,
    DocPipe
  ],
  imports: [
    CommonModule,
    ProjectsRoutingModule,
    NgxSkeletonLoaderModule,
    ParticleModule
  ],
  exports: [
    ImgStoragePipe,
    DocPipe
  ]
})
export class ProjectsModule { }
