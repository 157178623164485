import {Component, EventEmitter, HostListener, Inject, LOCALE_ID, Output, ViewChild} from '@angular/core';
import {Router} from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  navigation = false;
  isOpen = false;


  @ViewChild('autocomplete') autocomplete;

  /** Event that is emitted when the search bar is closed. */
  readonly closed: EventEmitter<void> = new EventEmitter<void>();

  /** Event that is emitted when the search bar is opened. */
  readonly opened: EventEmitter<void> = new EventEmitter<void>();

  languages = [
    { code: 'en', label: 'English'},
    { code: 'fr', label: 'Français'}
  ];


  constructor(private router: Router, @Inject(LOCALE_ID) protected localeId: string) {
    router.events.subscribe((val) => {
      this.navigation = false;
      this.handleClose();
    });
  }

  @HostListener('click', ['$event.target'])
  clickEvent(target) {
    if (target.classList[0] === 'search' || target.classList[0] === 'search-input' || target.classList[0] === 'search-icon') {
      this.handleOpen();
    } else {
      this.handleClose();
    }
  }

  handleOpen() {
    if (!this.isOpen) {
      const searchBtn = document.querySelector('.search');
      const searchInput = document.querySelector('.search-input');
      if (!this.isOpen) {
        searchBtn.classList.add('active');
        searchInput.classList.add('active');
        this.isOpen = true;
        this.autocomplete.focus();
        this.autocomplete.open();
        this.opened.emit();
      }
    }
  }

  handleClose() {
    if (this.isOpen) {
      const searchBtn = document.querySelector('.search');
      const searchInput = document.querySelector('.search-input');
      searchBtn.classList.remove('active');
      searchInput.classList.remove('active');
      this.isOpen = false;
      this.autocomplete.close();
      this.closed.emit();
    }
  }
}
